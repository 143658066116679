import React, { useEffect, useState } from 'react'

import { styled } from '@mui/material/styles'
// import {
//   localStorageWrapper,
//   isBrowser,
//   Button,
//   Link,
// } from '@boelensman1/amazon'

import {
  Paper,
  Typography,
  // Grid, Container
} from '@mui/material'

// import { smcPrivacyUrl } from 'config'
import supportedBrowsers from '../supportedBrowsers'

const Root = styled(Paper)({
  position: 'fixed',
  width: '100%',
  zIndex: 99999,
  bottom: 0,
  flexGrow: 1,
})

const UnsupportedBrowserCheckRoot = styled('div')(({ theme }) => ({
  color: 'white',
  backgroundColor: '#EA4C3C',
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  textAlign: 'center',
}))

// const TrackingNoticeRoot = styled('div')(({ theme }) => ({
//   color: 'white',
//   backgroundColor: theme.palette.background.trackingNotice,
//   paddingTop: theme.spacing(2),
//   paddingBottom: theme.spacing(2),
// }))

// const StyledLink = styled(Link)({
//   color: 'white',
//   textDecoration: 'underline',
// })

// const TrackingNotice = () => {
//   const [show, setShow] = useState(false)

//   const handleInteraction = () => {
//     setShow(true)
//     window.removeEventListener('scroll', handleInteraction)
//     window.removeEventListener('mousemove', handleInteraction)
//   }

//   useEffect(() => {
//     if (!isBrowser()) {
//       return
//     }

//     if (localStorageWrapper.getItem('trackingNoticeDismissedSet')) {
//       // cookie warning already shown
//       return
//     }

//     window.addEventListener('scroll', handleInteraction)
//     window.addEventListener('mousemove', handleInteraction)

//     // eslint-disable-next-line consistent-return
//     return () => {
//       window.removeEventListener('scroll', handleInteraction)
//       window.removeEventListener('mousemove', handleInteraction)
//     }
//   }, []) // eslint-disable-line react-hooks/exhaustive-deps

//   const handleClose = () => {
//     localStorageWrapper.setItem('trackingNoticeDismissedSet', true, 365)
//     setShow(false)
//   }

//   if (!show) {
//     return null
//   }

//   return (
//     <TrackingNoticeRoot>
//       <Container>
//         <Grid
//           container
//           // justifyContent="space-around"
//           alignItems="center"
//           spacing={2}
//         >
//           <Grid item xs={12} sm={12} md>
//             <Typography variant="body2">
//               Wij meten en analyseren het gebruik van onze website door middel
//               van de privacyvriendelijke analytics-tool Matomo. Lees ook{' '}
//               <StyledLink
//                 href={smcPrivacyUrl}
//                 target="_blank"
//                 trackingId="privacyPolicy"
//               >
//                 onze privacyverklaring
//               </StyledLink>
//               .
//             </Typography>
//           </Grid>
//           <Grid item>
//             <Button
//               sx={{
//                 color: 'default',
//                 '&:hover': {
//                   backgroundColor: 'grey.300',
//                 },
//               }}
//               onClick={handleClose}
//               disableElevation
//               variant="default"
//             >
//               Oké, ik begrijp het
//             </Button>
//           </Grid>
//         </Grid>
//       </Container>
//     </TrackingNoticeRoot>
//   )
// }

const UnsupportedBrowserCheck = () => {
  const [isUnsupported, setIsUnsupported] = useState(false)

  // for serverside rendering we have to do this in useEffect
  useEffect(() => {
    setIsUnsupported(!supportedBrowsers.test(navigator.userAgent))
  }, [])

  if (isUnsupported) {
    return (
      <UnsupportedBrowserCheckRoot>
        <Typography>
          Uw webbrowser is verouderd. Update uw browser voor meer veiligheid,
          snelheid en om deze site optimaal te kunen gebruiken.
        </Typography>
      </UnsupportedBrowserCheckRoot>
    )
  }
  return null
}

const Notices = () => (
  <Root>
    {/* <TrackingNotice /> */}
    <UnsupportedBrowserCheck />
  </Root>
)

export default Notices
