/* eslint-disable prefer-destructuring */
// get environment variables
// process.env is a special object so we can't use destructuring
const GATSBY_BASE_URL = process.env.GATSBY_BASE_URL
const GATSBY_USE_HTTP = process.env.GATSBY_USE_HTTP
const GATSBY_DONT_USE_WWW = process.env.GATSBY_DONT_USE_WWW
const GATSBY_API_URL = process.env.GATSBY_API_URL
const GATSBY_DEFAULT_SUBDOMAIN = process.env.GATSBY_DEFAULT_SUBDOMAIN
const GATSBY_BRANCH = process.env.GATSBY_BRANCH
const GATSBY_COMMIT_REF = process.env.GATSBY_COMMIT_REF

if (!GATSBY_BRANCH) {
  throw Error(
    `There must be a branch defined, make sure the GATSBY_BRANCH environment variable is set`,
  )
}

if (!GATSBY_COMMIT_REF) {
  throw Error(
    `There must be a commit ref defined, make sure the GATSBY_COMMIT_REF environment variable is set`,
  )
}

const https = !GATSBY_USE_HTTP
const www = !GATSBY_DONT_USE_WWW
const defaultSubdomain = GATSBY_DEFAULT_SUBDOMAIN || 'www'

const baseUrl = GATSBY_BASE_URL || 'relayzer.com'

const makeUrl = (path, subdomain = www ? defaultSubdomain : '') =>
  `http${https ? 's' : ''}://${subdomain}${
    subdomain ? '.' : ''
  }${baseUrl}${path}`

const apiUrl = {
  production: GATSBY_API_URL || makeUrl('', 'lj'),
  staging: GATSBY_API_URL || makeUrl('', 'lj.staging'),
}
const homeUrl = `https://www.${baseUrl}`

const sessionTokenTimeToLive = 86400 // 1 day, should be the same as in lj2

const defaultLanguage = 'en'
const languages = ['en']

const version = `${GATSBY_BRANCH} [${GATSBY_COMMIT_REF.substring(0, 8)}]`

module.exports = {
  apiUrl,
  homeUrl,

  sessionTokenTimeToLive,

  defaultLanguage,
  languages,

  version,
}
