import React, { useEffect } from 'react'

import { useQuery } from 'react-query'

import {
  localStorageWrapper,
  LoadingIndicator,
  SSRCompatibleSuspense,
} from '@boelensman1/amazon'

import { getUserData } from 'queries'
import { useAddNotification } from 'store/notifications'

import { useStore } from './store'
import { actions, authStates } from './actions'

const Layout = React.lazy(() => import('../../components/Layout'))
const SetPasswordScreen = React.lazy(() =>
  import('../../components/SetPasswordScreen'),
)

const AuthWrapper = ({ children }) => {
  const addNotification = useAddNotification()
  const [{ isAuthenticated, authState }, dispatch] = useStore()

  useQuery(['userData'], getUserData(isAuthenticated), {
    retry: (failureCount, error) =>
      // don't retry on permission denied
      failureCount <= 3 && error.statusCode !== 401 && error.statusCode !== -1,
    staleTime: 60 * 60 * 1000, // 1 hour
    onError: (err) => {
      if (err.statusCode === -1) {
        // probably not authenticated, but we didn't try to reduce serverload
        dispatch(actions.setAuthenticated(false))
        return
      }

      // if it's a real error, unauthenticate
      if (err !== 'Missing queryFn') {
        if (err.statusCode === 401) {
          dispatch(actions.setAuthenticated(false))
        } else {
          addNotification('requestError', {
            severity: 'error',
          })
        }
      }
    },
    onSuccess: (data) => {
      dispatch(
        actions.setAuthenticated(true, {
          hasPassword: data.hasPassword,
          skippedSettingPassword: data.flags.includes('skippedSettingPassword'),
        }),
      )
      if (window.posthog) {
        posthog.identify(data.email, {
          name: data.fullName,
          email: data.email,
        })
      }
    },
  })

  useEffect(() => {
    const loginEmail = localStorageWrapper.getItem('loginEmail')
    if (loginEmail) {
      dispatch(actions.setLoginEmail(loginEmail))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (authState === authStates.AUTHENTICATED_SHOULD_ASK_PASSWORD) {
    return (
      <SSRCompatibleSuspense fallback={<LoadingIndicator />}>
        <Layout noMenu>
          <SetPasswordScreen />
        </Layout>
      </SSRCompatibleSuspense>
    )
  }

  return children
}

export default AuthWrapper
