import auth from './auth.json'
import buttons from './buttons.json'
import formFields from './formFields.json'
import errors from './errors.json'
import formErrors from './formErrors.json'
import strings from './strings.json'
import notifications from './notifications.json'

export default {
  auth,
  buttons,
  formFields,
  formErrors,
  errors,
  strings,
  notifications,
}
