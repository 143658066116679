/* eslint-disable no-underscore-dangle */

import { isBrowser } from '@boelensman1/amazon'

const noop = () => {}

// very small wrapper around analytics to make sure that if not available (e.g. if the user has an adblocker) we don't get errors
const analytics = {
  fireEvent:
    isBrowser() && window?._paq
      ? (event) => {
          const [action, name] = event.split('/')
          const category = action
          try {
            window._paq.push([
              'trackEvent',
              category || 'generic',
              action || 'generic',
              name,
            ])
          } catch (e) {
            // log, but don't error
            console.error(e) // eslint-disable-line
          }
        }
      : noop,
}

export default analytics
