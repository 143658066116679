import { AmazonStore } from '@boelensman1/amazon'
import { apiUrl } from 'src/config'

const baseRequest =
  (method) =>
  async (path, options = {}) => {
    const {
      data,
      expectedStatusCode,
      includeCredentials,
      isTestUser,
      authorization,
    } = options

    let search = ''
    if (method === 'GET' && data) {
      search = `?${new URLSearchParams(data)}`
    }

    let body
    if ((method === 'POST' || method === 'PUT' || method === 'PATCH') && data) {
      body = JSON.stringify(data)
    }

    const env = AmazonStore.getEnv()
    const url = `${apiUrl[env]}${path}${search}`
    const response = await fetch(url, {
      method,
      body,
      headers: {
        'Content-Type': 'application/json',
        'is-test': isTestUser,
        authorization,
      },
      credentials: includeCredentials ? 'include' : 'omit',
    }).then((r) => r.json())

    if (response.statusCode !== (expectedStatusCode || 200)) {
      const err = new Error()
      err.statusCode = response.statusCode
      if (response.message) {
        err.message = response.message
        throw err
      }
      if (response.error) {
        err.message = response.error
        throw err
      }
      err.response = response
      err.message = 'Unknown error'
      throw err
    }
    return response.result
  }

const apiClient = {
  get: baseRequest('GET'),
  post: baseRequest('POST'),
  put: baseRequest('PUT'),
  patch: baseRequest('PATCH'),
  del: baseRequest('DELETE'),
}

export default apiClient
