import { createTheme, responsiveFontSizes } from '@mui/material/styles'

import InterVariable from 'src/fonts/Inter-roman.var.woff2'

const baseSpacing = 8

// A custom theme for this app
const theme = createTheme({
  spacing: baseSpacing,
  palette: {
    primary: {
      main: '#0B5FD5',
    },
    background: {
      page: '#fafafa', //grey[50]
    },
  },
  typography: {
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',

    body: {},

    // the heading used in sign in, signup, and 'tell us more about you' forms before authenticating
    h1Unauthenticated: {
      fontSize: '2.5rem',
      lineHeight: 1, //Usage of unitless line heights required for responsive font sizing.
      fontWeight: 600,
    },

    //
    h1: {
      fontSize: '2rem',
      fontWeight: 600,
    },

    h2: {
      fontSize: '1.3rem',
      fontWeight: 600,
    },

    h3: {
      fontSize: '1.15rem',
      fontWeight: 500,
    },

    accordion: {
      fontWeight: 500,
    },
    tag: {
      // color = secondary
      color: '#666666',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflowX: 'hidden',
        },
        '@font-face': {
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontDisplay: 'swap',
          fontWeight: '100 900',
          src: `url(${InterVariable}) format('woff2')`,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // no capitalize
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none', // no capitalize
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 3,
      },
    },
  },
})

export default responsiveFontSizes(theme, {
  variants: ['h1Unauthenticated'],
})
