export const types = {
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
  REMOVE_OLDEST_NOTIFICATION: 'REMOVE_OLDEST_NOTIFICATION',
}

export const actions = {
  addNotification: (
    message,
    { severity, autoHideDuration, id, closeOnClickAway, addCloseButton } = {},
  ) => ({
    type: types.ADD_NOTIFICATION,
    payload: {
      id,
      message,
      severity: severity || 'info',
      autoHideDuration: autoHideDuration || (addCloseButton ? null : 6000),
      addCloseButton,
      closeOnClickAway:
        closeOnClickAway === undefined ? true : closeOnClickAway,
    },
  }),
  removeNotification: ({ id }) => ({
    type: types.REMOVE_NOTIFICATION,
    payload: {
      id,
    },
  }),
  removeOldestNotification: () => ({
    type: types.REMOVE_OLDEST_NOTIFICATION,
  }),
}
